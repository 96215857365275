body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: black;
  font-size: 16px;
  line-height: 160%;
  // background: #77a0de;
  background: #d8e5ff;
}


* {
  box-sizing: border-box;
}

input,
textarea {
  -webkit-appearance: none;
  background-color: var(--ds-background-input, #fafbfc);
  border: none;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px var(--ds-border-input, #dfe1e6);
  box-sizing: border-box;
  display: block;
  line-height: 20px;
  margin-bottom: 12px;
  outline: none;
  padding: 8px 12px;
  transition-duration: 85ms;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease;
}

input:hover,
textarea:hover {
  box-shadow: inset 0 0 0 2px var(--ds-border-focused, #0079bf);
  background: #e4e4e4;
}

input:focus,
textarea:focus {
  box-shadow: inset 0 0 0 2px var(--ds-border-focused, #0079bf);
  background: #e4e4e4;
}

p {
  margin: 0;
  padding: 0;
}

ul,
li {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: white;
}

.top {
  position: relative;
  top: 0;
  left: 0;
}

.top::before,
.top::after {
  position: absolute;
  display: block;
  content: '';
  z-index: -1;
  opacity: .4;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 0;
}

.top::before {
  background: url(../../public/blob1.svg) no-repeat 0 0 / cover;
  width: 300px;
  height: 584px;
}

.top::after {
  background: url(../../public/blob2.svg) no-repeat 0 0 / cover;
  right: 0;
  width: 702px;
  height: 389px;
}

@import "../components/Header/Header.scss";


/* section intro start */

.section {
  padding-bottom: 120px;
}

.section_wrapper {
  width: 73%;
  margin: 0 auto;
}

.intro {
  display: flex;
  padding-top: 130px;
  position: relative;
  justify-content: space-between;
}

.title_info_btn {
  z-index: 5;
}

.photo__wrapper {
  margin: 0;
  padding: 0;
  width: 45%;
  position: relative;

  &:after {
    content: "";
    width: calc(100% + 50px);
    height: calc(100% - 50px);
    position: absolute;
    border-radius: 15px;
    top: 20px;
    left: -30px;
    z-index: 1;
    border: 4px solid #352A40;
  }
}

.photo {
  height: 560px;
  border-radius: 15px;
  z-index: 4;
  overflow: hidden;
  position: relative;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.25);

  img {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    object-fit: cover;
    z-index: 2;
    box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, .25);
  }
}



.title {
  font-size: 80px;
  position: absolute;
  font-weight: 800;
  margin-top: 50px;
  color: #352A40;
}

.info {
  position: relative;
  font-size: 20px;
  font-weight: 500;
  color: #352A40;
}

.info_btn {
  display: flex;
  flex-direction: column;
  width: 45%;
  position: absolute;
  bottom: 0;
}

.text1 {
  margin-bottom: 30px;
}

.request__btn {
  color: #352a40;
  padding: 17px 50px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  border-radius: 12px;
  font-size: 25px;
  font-weight: 800;
  position: relative;
  cursor: pointer;
  border: 1px solid;
  transition: all 0.5s ease;
  background-size: 201% 100%;
  background-image: linear-gradient(to left, transparent, transparent 50%, #352a40 50%, #352a40);
  background-position: 100% 0;

  &:hover,
  &:active {
    color: white;
    transition: all 0.3s ease;
    background-position: 0 0;
  }
}

/* arrow */
.arrow_container {
  display: flex;
  justify-content: center;
}

.arrow {
  position: absolute;
  width: 2.1rem;
  height: 0.48rem;
  opacity: 0;
  transform: scale(0.3);
  animation: move-arrow 3s ease-out infinite;
}

.arrow:first-child {
  animation: move-arrow 3s ease-out 1s infinite;
}

.arrow:nth-child(2) {
  animation: move-arrow 3s ease-out 2s infinite;
}

.arrow:before,
.arrow:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #2c3e50;
}

.arrow:before {
  left: 0;
  transform: skewY(30deg);
}

.arrow:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

@keyframes move-arrow {
  25% {
    opacity: 1;
  }

  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }

  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }

  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}

/* section intro end */






/* section about start */

.about {
  padding: 0 0 120px 0;

  &-me {
    padding: 50px 0 120px;
    font-size: 20px;
    padding: 20px;
    background-color: #6693df;
    border-radius: 15px;
    color: white;

    &__title {
      font-size: 48px;
      font-weight: 700;
      margin: 10px 0 55px;
      color: white;
    }
  }
}

.section__title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 55px;
  color: #352A40;
  line-height: 45px;
}

.about-content {
  display: flex;
  justify-content: space-between;

}

.about-photo {
  width: 560px;
  height: 465px;
  border-radius: 15px;
  z-index: 4;
  overflow: hidden;
  position: relative;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.25);

  img {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    object-fit: cover;
    z-index: 2;
  }
}

.about-text {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 80%;
}

.border_div {
  border-radius: 15px;
  font-weight: 500;
  padding: 18px;
  background: rgba(255, 255, 255, 0.7803921569);
  border: 1px solid;
  background: none;
}

.text3 {
  font-weight: 400;
  margin: 20px 0 0 0;
  border-radius: 15px;
  padding: 18px;
  position: relative;
  max-height: 300px;
  overflow: hidden;
  border: 1px solid;
}



.moreButton,
.lessButton {
  position: absolute;
  bottom: 3px;
  right: 3px;
  margin: 0;
  cursor: pointer;
  display: none;
}

.moreButton {
  background-color: #a9afba;
  border: none;
  padding: 5px 8px;
  border-radius: 15px;
  color: black;
}

.lessButton {
  background-color: #000;
  border: none;
  color: #fff;
  padding: 5px 8px;
  border-radius: 15px;
}

.text3 p {
  margin-bottom: 10px;
}

.text4 {
  font-weight: 400;
  margin: 20px 0 0 0;
  border-radius: 15px;
  padding: 18px;
  border: 1px solid;
  position: relative;
  max-height: 200px;
  overflow: hidden;
}

.text4 p {
  margin-bottom: 10px;
}



/* section about end */






/* section concept start */

.concept {
  padding-bottom: 175px;


  &_img_text {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 20px;
  }

  &_text {
    position: absolute;
    display: flex;
    left: 140px;
    color: #352A40;
    flex-direction: column;
  }

  &_text p {
    font-size: 40px;
    font-weight: 600;
    padding: 30px
  }
}

/* section concept end */

@import "../components/Footer/Footer.scss";
@import "../components/LawHelpPage/LawHelpPage.scss";
@import "../components/SubscriberService/SubscriberService.scss";

// media

@media (max-width: 2350px) {
  .title {
    font-size: 120px;
  }

  .request__btn {
    width: 60%;
    padding: 17px 38px;
    font-size: 22px;
  }

  .about-photo {
    width: 560px;
    height: 407px;
  }
}

@media (max-width: 2100px) {
  .title {
    font-size: 110px;
  }

  .request__btn {
    width: 70%;
    padding: 17px 38px;
    font-size: 22px;
  }
}

@media (max-width: 1900px) {
  .title {
    font-size: 100px;
  }
}

@media (max-width: 1892px) {

  .moreButton,
  .lessButton {
    display: block;
  }
}

@media (max-width: 1760px) {
  .header__container {
    width: 85%;
  }

  .title {
    font-size: 90px;
  }

  .request__btn {
    width: 75%;
    padding: 17px 38px;
    font-size: 22px;
  }

  .contact-box p {
    width: 70%;
  }
}

@media (max-width: 1622px) {
  .about-photo {
    height: 432px;
  }
}

@media (max-width: 1600px) {
  .title {
    font-size: 85px;
  }

  .request__btn {
    width: 80%;
    padding: 16px 35px;
    font-size: 22px;
  }
}

@media (max-width: 1500px) {
  .header__container {
    width: 90%;
  }

  .title {
    font-size: 80px;
  }

  .request__btn {
    width: 85%;
    padding: 16px 35px;
    font-size: 22px;
  }

  .subscriber-service_request__btn {
    width: 320px;
  }
}

@media (max-width: 1412px) {
  .header__container {
    width: 73%;

    &-menu_open {
      display: none;
    }

    .openly {
      display: flex;
    }

    &-menu__link {
      margin-right: 15px;

      &:last-child {
        margin: 0;
      }
    }

    .mobile-menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      height: 18px;
      margin-left: 15px;
    }
  }

  .footer-container__title-contact {
    right: 72px;
    font-size: 94px;
  }

  .footer-container__content .contact-box__sn {
    right: 6%;
  }
}

@media (max-width: 1380px) {
  .header__container {
    width: 85%;
  }

  .section_wrapper {
    width: 85%;
  }

  .law_wrapper {
    width: 85%;
  }

  .subscriber-service__wrap {
    width: 85%;
  }
}

@media (max-width: 1244px) {
  .about-me__title {
    font-size: 40px;
    margin: 10px 0 38px;
  }

  .section__title {
    font-size: 40px;
    margin-bottom: 38px;
  }

  .title {
    font-size: 75px;
  }

  .image_k {
    width: 180px;

    img {
      width: 100%;
    }
  }

  .concept_text p {
    font-size: 32px;
    font-weight: 600;
    padding: 25px;
  }

  .footer-container__title-contact {
    right: 50px;
  }
}

@media (max-width: 1200px) {
  .header__container {
    &-menu__link {
      margin-right: 25px;

      &:last-child {
        margin: 0;
      }
    }

    .title {
      font-size: 70px;
    }
  }

  .request__btn {
    width: 90%;
    padding: 16px 35px;
    font-size: 22px;
  }

  .footer-container__title-contact {
    font-size: 85px;
    font-weight: 800;
    position: absolute;
    right: 10px;
  }

  .footer-container__form-title {
    font-size: 23px;
    font-weight: 500;
    padding: 0 0 30px;
  }
}

@media (max-width: 1120px) {
  .header__container {
    width: 90%;
  }

  .concept {
    padding-bottom: 120px;
  }

  .section_wrapper {
    width: 90%;
    margin: 0 auto;
  }

  .law_wrapper {
    width: 90%;
  }

  .subscriber-service__wrap {
    width: 90%;
  }
}

@media (max-width: 1050px) {
  .about-me__title {
    font-size: 35px;
    margin: 10px 0 30px;
  }

  .section__title {
    font-size: 35px;
    margin-bottom: 30px;
    line-height: 40px;
  }

  .request__btn {
    width: 100%;
    padding: 15px 38px;
    font-size: 20px;
  }

  .title {
    font-size: 65px;
  }

  .info {
    font-size: 18px;
  }

  .about-me {
    font-size: 18px;
  }

  .photo {
    height: 460px;
  }

  .subscriber-service__call-to-action {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    i {
      text-align: center;
    }

    span {
      width: 1px;
      height: 25px;
      margin: 20px 0;
    }
  }
}

@media (max-width: 1030px) {
  .footer-container__title-contact {
    font-size: 70px;
    font-weight: 800;
    right: 20px;
    top: 74px;
  }

  .footer-container__form-title {
    font-size: 20px;
    font-weight: 500;
    padding: 0 0 30px;
  }

  .footer-container__content {
    .form {
      width: 370px;

      &__btn {
        padding: 12px 31px;
        font-size: 16px;
      }

      &__description {
        margin-left: 28px;
        font-size: 12px;
      }

      &__input {
        padding: 14px 14px;
      }

      &__label {
        font-size: 14px;

        &-textarea {
          font-size: 14px;
        }
      }

      &__item-errors {
        font-size: 14px;
      }
    }

    .contact-box__title {
      font-size: 15px;
    }

    .contact-box__link {
      transition: 0.3s all;
      font-size: 14px;
    }

    .contact-box p {
      font-size: 14px;
    }

    .contact-box__link_map {
      font-size: 14px;
    }

    .contact-box__sn {
      right: 5%;
    }
  }

  .law_wrapper .lawhelp {
    .grid-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &__description {
        display: none;
      }
    }

    .grid-container {
      grid-template-rows: repeat(4, 0.5fr);
    }
  }

  .moreButton-grid,
  .lessButton-grid {
    display: block;
  }
}

@media (max-width: 990px) {
  .law_wrapper .lawhelp .grid-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 0.5fr);
  }
}

@media (max-width: 960px) {
  .request__btn {
    padding: 14px 36px;
  }

  .title {
    font-size: 60px;
  }

  .info {
    font-size: 17px;
  }

  .about-me {
    font-size: 17px;
  }

  .footer-container__content {
    .form {
      width: 80%;
    }

    .contact-box {
      padding: 65px 0 0 35px;
    }
  }
}

@media (max-width: 900px) {
  .request__btn {
    padding: 14px 36px;
    font-size: 19px;
  }

  .title {
    font-size: 55px;
  }
}

@media (max-width: 872px) {
  .text3 {
    max-height: 272px;
  }

  .image_k {
    width: 162px;
  }

  .concept_text {
    left: 103px;

    p {
      font-size: 28px;
      font-weight: 600;
      padding: 18px;
    }
  }

  .footer-container__form-title {
    font-size: 18px;
    font-weight: 500;
    padding: 0 0 30px;
  }

  .footer-container__title-contact {
    font-size: 66px;
    font-weight: 800;
    right: 20px;
    top: 74px;
  }
}

@media (max-width: 840px) {
  .top::after {
    width: 522px;
    height: 204px;
  }

  .header__container {
    width: 95%;

    &_open-menu {
      margin: 60px 0;
      font-size: 21px;
    }
  }

  .section_wrapper {
    width: 95%;
  }

  .law_wrapper {
    width: 95%;
  }

  .subscriber-service__wrap {
    width: 95%;
  }

  .request__btn {
    padding: 10px 25px;
    font-size: 17px;
    width: 50%;
    right: 20px;
    position: absolute;
    box-shadow: 1px 1px 20px rgb(37 33 33 / 20%);
  }

  .title {
    font-size: 50px;
    margin: 30px 20px;
  }

  .info {
    &_btn {
      position: absolute;
      margin: 20px;
      padding: 20px;
      bottom: 0;
      left: 0;
      right: 0;
      width: auto;
      background: rgba(255, 255, 255, 0.7803921569);
      border-radius: 10px;
      flex-direction: row;
      align-items: flex-end;
    }
  }

  .text2 {
    width: 45%;
  }

  .photo__wrapper {
    width: 100%;

    &:after {
      content: none;
    }
  }

  .photo {
    height: 610px;
  }

  .text1 {
    margin-bottom: 17px;
  }

  .footer-container__content .form__description {
    margin-left: 17px;
    font-size: 11px;
  }

  .footer-container__content .form {
    &__label {
      font-size: 13px;

      &-textarea {
        font-size: 13px;
      }
    }

    &__item-errors {
      font-size: 13px;
    }

    &__btn {
      padding: 10px 25px;
      font-size: 15px;
    }
  }

  .notification {
    height: 179px;
    width: 360px;

    .message {
      font-size: 20px;
    }
  }
}

@media (max-width: 770px) {
  .footer-container__content {
    .form__btn_description {
      display: flex;
      margin-top: 10px;
      flex-direction: column;
      align-items: center;
    }

    .form__description {
      font-size: 11px;
      margin: 26px 0 0;
      border-top: 1px solid white;
      line-height: 18px;
      padding-top: 5px;
    }

    .contact-box__sn {
      right: 4%;
    }
  }

  .footer-container__title-contact {
    font-size: 60px;
    right: 20px;
    top: 78px;
  }

  .footer-container__form-title {
    font-size: 16px;
    font-weight: 500;
    padding: 0 0 30px;
  }
}

@media (max-width: 740px) {
  .text3 {
    max-height: 248px;
  }

  .header__container_open-menu {
    font-size: 19px;
  }

  .header__container-logo {
    height: 82px;
  }
}

@media (max-width: 650px) {
  .top::before {
    display: none;
  }

  .top::after {
    width: 414px;
    height: 194px;
  }

  .header_open {
    width: 63%;
  }

  .info {
    &_btn {
      flex-wrap: wrap;
    }
  }

  .text2 {
    width: 100%;
    margin-bottom: 0;
  }

  .request__btn {
    padding: 10px 30px;
    width: auto;
    position: relative;
    right: 0;
    margin-top: 20px;
  }

  .concept_text {
    left: 91px;

    p {
      font-size: 27px;
    }
  }

  .footer-container__border2 {
    height: 1px;
    width: 100%;
    top: 671px;
    left: 0;
  }

  .footer-container__border3 {
    right: 12%;
    left: auto;
  }

  .footer-container__form {
    width: 72%;
  }

  .footer-container__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-container__title-contact {
      right: 0;
      top: 86px;
      left: 0;
    }

    .contact-box {
      width: 59%;
      padding: 149px 0 0;
      position: relative;

      p {
        width: 100%;
      }
    }

    .contact-box__sn {
      right: -26%;
      top: 175px;
      bottom: 0;
    }
  }
}


@media (max-width: 622px) {
  .about-photo {
    height: 457px;
  }
}

@media (max-width: 612px) {
  .about-me__title {
    font-size: 32px;
    margin: 10px 0 25px;
  }

  .section__title {
    font-size: 32px;
    margin-bottom: 25px;
    line-height: 34px;
  }

  .about-photo {
    display: none;
  }

  .about-text {
    margin-left: 0;
    width: 100%;
  }

  .concept_text {
    left: 75px;

    & p {
      font-size: 23px;
      padding: 13px;
    }
  }

  .image_k {
    width: 135px;
  }
}

@media (max-width: 590px) {
  .title {
    font-size: 40px;
    margin: 25px 20px;
    width: 30%;
    line-height: 33px;
  }

  .photo img {
    width: 130%;
    height: 155%;
  }

  .about-me__title {
    margin: 5px 0 25px;
  }

  .subscriber-service-advantage {
    align-items: center;
    flex-direction: column;
    text-align: center;

    img {
      margin: 5px 0 10px 0;
      width: 35px;
    }
  }

  .subscriber-service__wrap h2 {
    text-align: center;
  }

  .subscriber-service__assistance {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0 0 40px 0;
      flex-direction: column;

      &-img {
        width: 45px;
        margin: 0 0 10px 0;
      }

      p {
        max-width: 100%;
        text-align: center;
      }
    }
  }
}

@media (max-width: 520px) {
  .header_open {
    width: 100%;
  }

  .header__container-logo {
    height: 75px;
  }

  .image_k {
    width: 122px;
  }

  .concept_text {
    left: 60px;

    & p {
      font-size: 22px;
      padding: 12px;
    }
  }

  .footer-container {
    &__title-contact {
      font-size: 47px;
    }

    &__form {
      width: 65%;

      .form {
        width: 100%;
      }
    }

    .contact-box {
      width: 65%;
      padding: 149px 0 0;
      position: relative;

      &__sn {
        right: -23%;
        top: 175px;
        bottom: 0;

        &-icon {
          width: 25px;
        }
      }
    }
  }

  .law_wrapper .lawhelp .grid-item {
    padding: 0.5rem;

    &__title {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .subscriber-service_request__btn {
    width: 290px;
  }
}

@media (max-width: 475px) {
  .image_k {
    width: 110px;
  }

  .concept_text {
    left: 50px;

    & p {
      font-size: 21px;
      padding: 10px;
    }
  }

  .law_wrapper .lawhelp {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(12, 0.5fr);
    }

    .grid-item {
      flex-direction: column;
    }
  }
}

@media (max-width: 442px) {
  .image_k {
    width: 100px;
  }

  .concept_text {
    left: 40px;

    & p {
      font-size: 19px;
      padding: 8px;
    }
  }

  .footer-container__content .form__input {
    padding: 12px 12px;
  }
}

@media (max-width: 430px) {
  .photo img {
    width: 143%;
    height: 137%;
  }

  .footer-container__content .form {
    &__item-errors {
      font-size: 12px;
    }

    &__btn {
      padding: 10px 25px;
      font-size: 15px;
    }
  }

  .notification {
    height: 149px;
    width: 300px;

    &-wrap {
      flex-direction: column;


      .done {
        width: 38px;
        height: 38px;
        margin: 0 0 7px;
      }

      .message {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 390px) {
  .header__container {
    &-menu__link_phone {
      display: none;
    }

    &-logo {
      height: 70px;
    }
  }

  .info {
    &_btn {
      margin: 15px;
      padding: 15px;
    }
  }

  .request__btn {
    padding: 10px 22px;
    font-size: 15px;
    width: 100%;
  }

  .title {
    margin: 18px 15px;
  }

  .about-me {
    padding: 18px;

    &__title {
      margin: 0 0 25px;
    }
  }

  .image_k {
    width: 90px;
  }

  .concept_text {
    left: 35px;

    & p {
      font-size: 18px;
      padding: 8px;
    }
  }

  .notification {
    height: 140px;
    width: 247px;

    .done {
      width: 36px;
      height: 36px;
      margin: 0 0 5px;
    }

    .message {
      font-size: 15px;
    }
  }
}

@media (max-width: 360px) {
  .concept_text {
    left: 0;
  }
}




// media

.expanded {
  max-height: none;
}