.footer {
    background: #0d0c0d;
    color: white;
    padding: 76px 0 30px;
    position: relative;


    &-container {
        &__form {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 50%;

            &-title {
                font-size: 25px;
                font-weight: 500;
                padding: 0 0 30px;
            }
        }

        &__border1 {
            position: absolute;
            top: 124px;
            left: 0;
            width: 100%;
            border: 1px solid #ffffff;
        }

        &__border2 {
            position: absolute;
            height: 100%;
            right: 50%;
            left: 50%;
            bottom: 0;
            top: 0;
            margin: 0 auto;
            border: 1px solid #ffffff;
        }

        &__border3 {
            position: absolute;
            height: 100%;
            right: 10%;
            left: 90%;
            bottom: 0;
            top: 0;
            margin: 0 auto;
            border: 1px solid #ffffff;
        }

        &__title-contact {
            font-size: 100px;
            font-weight: 800;
            position: absolute;
            right: 109px;
            top: 63px;
        }

        &__content {
            display: flex;

            .form {
                width: 430px;
                margin-top: 35px;

                &__item {
                    padding-bottom: 17px;
                    position: relative;
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;

                    &-il {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        position: relative;
                    }

                    &:last-child {
                        align-items: flex-start;
                    }

                    &-errors {
                        color: red;
                        margin: 0;
                        font-size: 15px;
                    }

                }

                &__input {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: none;
                    font-weight: 400;
                    font-size: 15px;
                    background: #D9D9D9;
                    padding: 15px 15px;
                    font-family: sans-serif;
                    margin: 0;

                    &_error {
                        border: 3px solid rgb(227, 18, 18);
                    }
                }

                &__textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: none;
                    font-weight: 400;
                    font-size: 15px;
                    min-height: 116px;
                    padding: 15px 15px;
                    resize: vertical;
                    overflow: hidden;
                    font-family: sans-serif;
                    max-height: 215px;
                    height: 116px;
                    background: #D9D9D9;
                    margin: 0;
                }

                &__textarea:focus~label,
                &__textarea:not(:placeholder-shown)~label,
                &__input:focus~label,
                &__input:not(:placeholder-shown)~label {
                    transform: translateY(-15px);
                    font-size: 14px;
                    background: #d9d9d9;
                    color: #4b4646;
                    line-height: 5px;
                    border-radius: 7px;
                    padding: 12px 10px;
                    margin: 0 0 0 5px;
                    top: 0;
                }

                &__label {
                    position: absolute;
                    padding: 0 15px;
                    color: #757575;
                    pointer-events: none;
                    transition: 0.5s;
                    font-weight: 400;
                    font-size: 15px;
                    font-family: sans-serif;
                    margin: 0;

                    &-textarea {
                        position: absolute;
                        padding: 12px 15px;
                        color: #757575;
                        pointer-events: none;
                        transition: 0.5s;
                        font-weight: 400;
                        font-size: 15px;
                        font-family: sans-serif;
                        margin: 0;
                        top: 0;

                        .sup_color {
                            color: red;
                        }
                    }

                    .sup_color {
                        color: red;
                    }
                }

                &__btn_description {
                    display: flex;
                    margin-top: 10px;
                    align-items: center;
                    justify-content: space-between;
                }

                &__btn {
                    padding: 12px 50px;
                    border-radius: 10px;
                    color: white;
                    background: #1e4cf0;
                    font-weight: 600;
                    font-size: 18px;
                    border: none;
                    cursor: pointer;
                    position: relative;

                    &:before {
                        content: '';
                        border-radius: 15px;
                        min-width: calc(100% + 7px);
                        min-height: calc(100% + 7px);
                        border: 3px solid #2555FF;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        transition: all .3s ease-in-out 0s;
                    }

                    &:hover::before {
                        opacity: 1;
                    }
                }

                &__description {
                    margin-left: 45px;
                    font-size: 13px;
                    text-align: center;
                }
            }

            .contact-box {
                width: 50%;
                padding: 65px 0 0 67px;
                display: flex;
                flex-direction: column;

                &__title {
                    color: #858585;
                    margin-top: 20px;
                }

                p {
                    margin-bottom: 8px;
                }

                &__link {
                    transition: 0.3s all;

                    &_map {
                        transition: 0.3s all;
                        color: #858585;

                        &:hover {
                            color: #c6c6c6;
                        }
                    }

                    &:hover {
                        color: #c0c0c0;
                    }
                }

                &__sn {
                    position: absolute;
                    right: 7%;
                    bottom: 100px;

                    &-icon {
                        display: block;
                        width: 30px;
                        margin-bottom: 10px;
                        transition: all .3s ease-in-out 0s;

                        &:hover {
                            transform: scale(1.1);
                            transition: all .2s ease-in-out 0s;
                        }

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.notification {
    display: flex;
    height: 223px;
    z-index: 10;
    width: 404px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 14px;
    box-shadow: 2px 0px 10px 2px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease-in-out;
    align-items: center;
    justify-content: center;

    &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.done {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.message {
    color: black;
    font-size: 24px;
    font-weight: 600;
}