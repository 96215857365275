.subscriber-service {
    &__wrap {
        width: 74%;
        margin: 0 auto;
        padding-top: 150px;
        font-size: 17px;
        color: #352A40;
        font-weight: 500;

        h2 {
            margin: 50px 0;
        }
    }

    &_ul {
        margin: 10px 0 10px 20px;
        font-weight: 500;

        &-list {
            list-style: inside;
            display: list-item;
        }
    }

    &__call-to-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 90px 0;

        span {
            width: 10%;
            height: 1px;
            display: block;
            background: black;
            margin: 0 20px;
        }
    }

    &_request__btn {
        color: #352a40;
        padding: 12px 20px;
        width: 280px;
        display: flex;
        justify-content: center;
        border-radius: 12px;
        font-size: 17px;
        font-weight: 800;
        position: relative;
        cursor: pointer;
        border: 1px solid;
        transition: all 0.5s ease;
        background-size: 201% 100%;
        background-image: linear-gradient(to left, transparent, transparent 50%, #352a40 50%, #352a40);
        background-position: 100% 0;

        &:hover,
        &:active {
            color: white;
            transition: all 0.3s ease;
            background-position: 0 0;
        }
    }

    &-advantage {
        display: flex;
        align-items: flex-start;
        margin-bottom: 50px;

        &:last-child {
            margin: 0;
        }

        img {
            margin: 5px 20px 0 0;
            width: 40px;
        }
    }

    &__assistance {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-item {
            width: 45%;
            display: flex;
            align-items: flex-start;
            margin: 0 20px 50px 0;

            &-img {
                width: 50px;
                margin: 0px 15px 0 0;
            }

            p {
                max-width: 80%;
                overflow-wrap: break-word;
            }
        }
    }
}