.header {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;

    &_open {
        position: absolute;
        z-index: 999;
        background: #e1eaf8;
        height: 170vh;
        padding: 30px 10px;
        top: auto;
        left: auto;
        right: 0;
        width: 50%;
        transition: all 0.5s ease-out;
    }

    &__container_open {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-menu {
            display: flex;
            z-index: 1;
            align-items: center;
            margin: 60px 20px;
            flex-direction: column;
            font-weight: 500;
            font-size: 24px;

            &__link {
                margin: 10px 0;
                color: black;


                &_phone {
                    margin: 10px 0;
                    color: black;
                }
            }

            .cross {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                position: absolute;
                top: 20px;
                right: 20px;
                cursor: pointer;
            }
        }


        .openlyy {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

    }

    &__container {
        display: flex;
        color: white;
        width: 74%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;

        &-logo {
            height: 90px;
            padding: 10px 0;
            cursor: pointer;
            z-index: 1;

            img {
                height: 100%;
            }
        }

        &-menu {
            display: flex;
            z-index: 1;
            align-items: center;
            font-size: 17px;
            font-weight: 600;

            &:hover &__link:hover {
                color: #656c75;
                border-bottom: 1px solid currentColor;
            }

            &__link {
                color: #352a40;
                margin-right: 35px;
                cursor: pointer;

                &:last-child {
                    margin: 0;
                }

                &_phone {
                    color: #352a40;
                    margin-left: 35px;
                    cursor: pointer;
                    font-size: 17px;
                    font-weight: 600;

                    &:hover {
                        color: #656c75;
                        border-bottom: 1px solid currentColor;
                    }
                }
            }

            .mobile-menu {
                display: none;
                cursor: pointer;

                &__line {
                    width: 25px;
                    height: 2px;
                    border-radius: 2px;
                }
            }
        }
    }
}

.background {
    position: fixed;
    background: #0d0c0d;
    opacity: 1;
    color: white;
    transition: all 0.5s ease-out;
}

.color {
    color: #f2f0e4;
}

.line-bg_w {
    background-color: #f2f0e4;
}

.line-bg_b {
    background-color: #352a40;
}