.law_wrapper {
    width: 74%;
    margin: 0 auto;

    .lawhelp {
        padding-top: 150px;

        .service-list__item {
            list-style: inside;
            display: list-item;
            font-size: 17px;
            color: #352A40;
            font-weight: 500;
        }

        .grid-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(4, 1fr);
            gap: 1rem;
            margin: 75px 0;
        }

        .grid-item {
            background-color: #6693df;
            color: #ffffff;
            padding: 1rem;
            text-align: center;
            border-radius: 5px;
            transition: 0.3s linear;

            &:hover {
                background-color: #4e6387;
                transform: scale(1.01);
                transition: 0.1s linear;
            }

            &__title {
                font-size: 18px;
                font-weight: 700;
            }

            &__description {
                margin-top: 10px;
                font-size: 17px;
                font-weight: 500;

                .show {
                    display: block;
                }
            }
        }
    }
}

.moreButton-grid,
.lessButton-grid {
    border: none;
    padding: 5px 8px;
    border-radius: 15px;
    margin-top: 5px;
    transition: all 0.5s ease-out;
    display: none;
    cursor: pointer;
}

.moreButton-grid {
    background-color: #a9afba;
}

.lessButton-grid {
    background-color: #000;
    color: #fff;
}